import { IonButton, IonContent, IonHeader, IonImg, IonModal, IonPage, IonTitle, IonToolbar } from '@ionic/react';

const Page404: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Page non trouvée</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class='ion-padding'>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Page non trouvée</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonModal
          isOpen={true}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Page non trouvée</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonImg style={{ paddingTop: '20vh' }} src={require('../assets/404-image.png')} />
            <IonButton expand='block' href='/'>Pssst par ici !</IonButton>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Page404;
