import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import './Tab1.css';
import { ReactComponent as StorkLogo } from '../assets/stork.svg';
import { ReactComponent as StampLogo } from '../assets/StampNetherlands.svg';
import { ReactComponent as BabyLogo } from "../assets/pram.svg"
import { heart, informationCircle } from 'ionicons/icons';
import { isPlatform } from '@ionic/react';
import { Player } from '@lottiefiles/react-lottie-player';
import data from "../assets/36686-send-it.json";
import firestore from "../firebase";
import { addDoc, collection, getDocs } from "@firebase/firestore";


const Tab1: React.FC = () => {

  const [playerName, setPlayerName] = useState<string>("");
  const [babyName, setBabyName] = useState<string>("");
  const [babyNames, setBabyNames] = useState<string[] | never[]>([]);
  const [isOpen, setIsOpen] = useState(true);
  const [isParticipationModalOpen, setIsParticipationModalOpen] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);
  const votes = collection(firestore, "votes");
  const [presentAlert] = useIonAlert();

  const handlePlayerName = (name: string) => {
    if (name.trim() === "") {
      setPlayerName("")
      return;
    }
    setPlayerName(name)
  }

  const handleParticipationClick = async () => {
    setBabyName("")
    setPlayerName("")
    try {
      await addDoc(votes, { createdOn: new Date(), voterName: playerName, voterNameChoice: babyName })
      setIsParticipationModalOpen(!isParticipationModalOpen)
    } catch (error) {
      presentAlert({
        header: 'Aïe!',
        subHeader: "Problème lors de l'envoi de votre participation. ",
        message: "Merci de vérifier que vous êtes bien connecté à internet.",
        buttons: ['OK'],
      })
    }

  }

  useEffect(() => {
    const names = collection(firestore, "names");
    const n: string[] = []
    getDocs(names)
      .then((snapshot) => {
        snapshot.forEach((doc) => n.push(doc.data().name))
        setBabyNames(n.sort())
      })
  }, [babyName])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Participer</IonTitle>
          {!isPlatform("ios") &&
            <IonButtons slot='end'>
              <IonButton id='open-modal' color="dark" onClick={() => setIsOpen(!isOpen)}><IonIcon slot='icon-only' icon={informationCircle} /></IonButton>
            </IonButtons>
          }
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Participer</IonTitle>
            <IonButtons slot='end'>
              <IonButton id='open-modal' color="dark" onClick={() => setIsOpen(!isOpen)}><IonIcon slot='icon-only' icon={informationCircle} /></IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>


        <IonCard className='fancy-border'>
          <IonCardContent>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <StorkLogo style={{ height: "30vh", margin: 10 }} />
              <StampLogo style={{ height: "10vh" }} />
            </div>
            <IonList>
              <IonItem>
                <IonLabel position="floating">Prénom / surnom du participant</IonLabel>
                <IonInput value={playerName} onIonChange={e => handlePlayerName(e.detail.value!)} clearInput></IonInput>
              </IonItem>

              <IonItem>
                <IonSelect value={babyName} placeholder="Choisir un prénom" onIonChange={(e) => setBabyName(e.detail.value)} cancelText='Annuler'>
                  {babyNames.map((bname: any) => {
                    return (
                      <IonSelectOption key={bname} value={bname}>{bname}</IonSelectOption>
                    )
                  })}

                </IonSelect>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonModal
          ref={modal}
          // trigger="open-modal"
          isOpen={isOpen}
          initialBreakpoint={0.75}
          breakpoints={[0, 0.5, 0.75]}
          backdropDismiss={true}
          backdropBreakpoint={0.25}
          onDidDismiss={() => setIsOpen(false)}
        >
          <IonContent className="ion-padding">
            <IonText color="dark" className='ion-text-center'>
              <p>Sébastien et Sophie vous invitent à essayer de trouver le futur prénom de leur deuxième fils !</p>
            </IonText>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <BabyLogo style={{ height: "40vh", width: "70vw" }} />
            </div>

            <IonText color="medium" className='ion-text-center'>
              <p>Made with <IonIcon color='danger' icon={heart} /> by tonton Julien</p>
            </IonText>
            <IonButton expand='full' onClick={() => { setIsOpen(false) }}>C'est parti !</IonButton>
          </IonContent>
        </IonModal>


        <IonModal
          isOpen={isParticipationModalOpen}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Merci d'avoir participé</IonTitle>
              <IonButtons slot="end">
                <IonButton strong={true} onClick={() => setIsParticipationModalOpen(!isParticipationModalOpen)}>
                  OK
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <Player
              autoplay
              speed={0.7}
              keepLastFrame
              src={data}
              style={{ height: '300px', width: '300px' }}
            >
            </Player>
            <IonText className='ion-text-center'>
              <p>Votre participation a bien été prise en compte !</p>
            </IonText>
          </IonContent>
          <IonFooter className="ion-no-border">
            <IonButton routerLink='/statistiques' onClick={() => setIsParticipationModalOpen(false)} expand='block'>Voir les statistiques</IonButton>
          </IonFooter>
        </IonModal>


      </IonContent>
      <IonFooter className="ion-no-border">
        <IonButton onClick={handleParticipationClick} disabled={playerName.length === 0 || babyName === ""} expand='block'>Participer</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Tab1;
