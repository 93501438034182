// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBgizqH10WYr93vMRzaX8EQx7WejhlNMtw",
  authDomain: "so-seb-baby2.firebaseapp.com",
  projectId: "so-seb-baby2",
  storageBucket: "so-seb-baby2.appspot.com",
  messagingSenderId: "981712750500",
  appId: "1:981712750500:web:70050e5aa2f357a3956331"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app)

export default firestore;